<template>
    <div class="item-slot-container">
        <p class="title" :class="{ 'fontHero': isHero }">{{title}}</p>
        <slot></slot>
    </div>
</template>
<script>
export default {
  props: ['title', 'isHero'],
};
</script>
<style lang="scss" scoped>
.item-slot-container {
    display:flex;
    flex-direction: column;
    gap: 1rem;
    // max-width: 500px;

    .title {
        font-family: Mulish;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
        color: #0C0F4A;
        margin: 0;
    }
    .title.fontHero {
        font-size: 24px;
        line-height: 32px;
    }
}
</style>
