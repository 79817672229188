<template>
  <div class="opportunity-right-sidebar-container">
    <ItemSlotComponent :isHero="false" title="Entry requirements" v-if="isCheckout && showTitle" />
    <div class="details" v-if="isCheckout">
      <p>To be eligible for this course, learners must have all the prerequisites.</p>
      <p class="check-eligibility" @click="() => toggleEligibility(true)">
        Check eligibility
        <inline-svg class="account-type-icon" :width="20" :height="20" :src="require(`@/assets/icons/arrow-right.svg`)">
        </inline-svg>
      </p>
    </div>
    <el-divider v-if="isCheckout" />
    <ItemSlotComponent :isHero="false" title="Summary" v-if="showTitle" />
    <div class="details">
      <p><span><img src="@/assets/icons/yellow-calendar-icon.svg"></span>{{ startDateLabel }} </p>
      <!-- <p><span><img src="@/assets/icons/yellow-duration-icon.svg"></span> Duration of days</p> -->
      <p><span><img src="@/assets/icons/yellow-mic-icon.svg"></span> English</p>
      <p><span><img src="@/assets/icons/yellow-domain-icon.svg"></span> On-Site lessons</p>
      <!-- <p><span><img src="@/assets/icons/yellow-location-icon.svg"></span></p> -->
      <p><span><img src="@/assets/icons/yellow-certificate-icon.svg"></span> Certification on completion</p>
    </div>
    <el-divider />
    <div class="prices" v-if="selectedCourse?.isAvailable">
      <div class="price-cover">
        <div>
          <p>Course fee</p>
        </div>
        <div class="price">
          <p class="before-price" v-if="selectedCourse?.id !== 2">£220</p>
          <p class="now-price" :class="{'dark': selectedCourse?.id === 2}">{{selectedCourse?.id !== 2 ? 'FREE' : '£180'}}</p>
        </div>
        <p class="price-cover__note-text default-text" v-if="selectedCourse?.id === 2">(NOTE: Payable directly with the training provider)</p>
      </div>
      <div class="price-cover">
        <div>
          <p>Service fee (inc. VAT)</p>
        </div>
        <div class="price">
          <p class="vat-price now-price">£{{ getServiceCharge }}</p>
        </div>
      </div>
    </div>
    <div class="cta" v-if="isCheckout && !selectedCourse?.isUpcoming">
      <el-checkbox v-model="agreedToTerms">
        <p>
          I have read and agree with the
          <span class="bg-yellow">Terms of Service & Privacy Policy</span>
        </p>
      </el-checkbox>
      <br>
      <el-button type="primary" class="yellow-button" :disabled="!agreedToTerms" round :loading="submitting"
        @click="goToBilling">SECURE MY SPOT
      </el-button>
    </div>
    <div class="cta" v-else>
      <!-- <el-button type="primary" class="yellow-button" round :loading="submitting" @click="onHandleClick">
        {{ !selectedCourse.hasSelectedDate ? 'Select date' : 'View details'}}
      </el-button> -->
      <el-button v-if="selectedCourse?.isAvailable" type="primary" class="yellow-button" round :loading="submitting" @click="gotoDetails">
        View details
      </el-button>
    </div>
  </div>

  <CourseEligibilityModal :show="showEligibility" @close="() => toggleEligibility(false)" />
</template>
<script>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { mapGetters } from 'vuex';

import CourseEligibilityModal from '@/core/components/opportunity/modals/CourseEligibilityModal.vue';
import appFilters from '@/filters';
import { OPPORTUNITY_BILLING_ROUTE } from '@/modules/opportunity-billing/routes';
import ItemSlotComponent from '@/modules/opportunity-details/components/ItemSlotComponent.vue';
import { OPPORTUNITY_BILLING_STORE } from '@/store/modules/opportunity-billing';

export default {
  props: {
    showTitle: Boolean,
    isCheckout: Boolean,
    data: Array,
    selectedCourse: Object
  },
  components: {
    ItemSlotComponent,
    CourseEligibilityModal
  },
  created() {
  },
  setup(props) {
    const router = useRouter();
    const agreedToTerms = ref(false);
    const showEligibility = ref(false);

    const ifDateSelected = computed(() => {
      return props.selectedCourse.hasSelectedDate;
    });

    const onHandleClick = () => {
      if (ifDateSelected.value % 2 === 0) {
        router.push({
          name: 'opportunity-select-spot',
        });
      } else {
        router.push({
          name: 'opportunity-details',
        });
      }
    };

    const gotoDetails = () => {
      router.push({
        name: 'opportunity-details',
        params: { courseId: props.selectedCourse.id }
      });
    };

    const goToBilling = () => {
      router.push({
        name: OPPORTUNITY_BILLING_ROUTE,
      });
    };

    return {
      agreedToTerms,
      onHandleClick,
      showEligibility,
      gotoDetails,
      goToBilling
    };
  },
  computed: {
    ...mapGetters(OPPORTUNITY_BILLING_STORE, ['getServiceCharge']),

    startDateLabel() {
      const { selectedCourse } = this;

      return selectedCourse?.selectedStartDate
        ? appFilters.formatToDatetimeWithMeridian(selectedCourse?.selectedStartDate) : 'Flexible start date';
    }
  },
  methods: {
    toggleEligibility(val) {
      this.showEligibility = val;
    }
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.opportunity-right-sidebar-container {
  //    margin-left: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  // border-left: 2px solid rgba(12, 15, 74, 0.1);
  // padding-left: 1rem;
  margin-top: 10px;
  padding-top: 1rem;
  width: 100%;

  //    min-width: 250px;
  .price-cover {
    display: flex;
    justify-content: space-between;
    position: relative;

    p {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.25px;
      text-align: left;
      color: #0C0F4A;
    }

    &__note-text {
      font-size: 10px !important;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: rgba(12, 15, 74, 0.5) !important;
      position: absolute;
      bottom: -.2rem;
      left: 0;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    p {
      margin: 0;
      display: flex;
      gap: 1rem;
      align-items: center;
      font-family: Mulish;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.25px;
      text-align: left;
      color: #0C0F4A;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .check-eligibility {
      color: #FFA500;
      justify-content: end;
      gap: unset;
      cursor: pointer;
    }
  }

  .el-divider {
    margin: 0;
  }

  .price {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    .before-price {
      font-family: Mulish;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.25px;
      text-align: left;
      position: relative;
      color: rgba(12, 15, 74, 0.5);
      margin: 0;

      &::before {
        content: "";
        width: 100%;
        height: 2px;
        background: rgba(12, 15, 74, 0.5);
        position: absolute;
        top: 45%;
        left: 0;
      }
    }

    .now-price {
      font-family: Mulish;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.25px;
      text-align: left;
      color: #FAA100;
      margin: 0;
    }

    .now-price.dark {
      color: rgba(12, 15, 74, 0.5);
    }

    .vat-price {
      color: #0C0F4A;
    }
  }

  .cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .5rem;

    .yellow-button {
      width: 100%;
      border-radius: 7px;
      background: #FAA200;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      letter-spacing: 1.25px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .is-disabled {
      background-color: rgba(12, 15, 74, 0.1);
    }

    p {
      margin: 0;
      font-family: Mulish;
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.25px;
      text-align: left;
      color: rgba(12, 15, 74, 0.5);
    }

    .el-checkbox {
      align-items: flex-start;

      .el-checkbox__label {
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        color: rgba(255, 255, 255, 1);

        p {
          white-space: normal;

          .bg-yellow {
            color: #FFA500;
          }
        }
      }

      :deep(.el-checkbox__input .el-checkbox__inner) {
        border-radius: 100px;
        background: rgba(12, 15, 74, 0.05);
        border: 0 solid transparent;
        width: 18px;
        height: 18px;
      }

      :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
        display: none;
      }

      :deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
        border: 0 solid transparent;
        background: rgba(12, 15, 74, 0.05);
        transition: 0.2s ease-in-out;
      }

      :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
        background: #ffa500;
        border: 4px solid #ffa500;
        transition: 0.2s ease-in-out;
      }
    }
  }
}

@include media-md-max-width {
  .opportunity-right-sidebar-container {
    width: 100%;
    border-top: 1px solid rgba(12, 15, 74, 0.1);
    padding: 1.5rem 0 2rem 0;
    .details {
      border-left: 0 !important;
      padding-left: 0 !important;
    }
  }
}

@include media-sm-max-width {
  .opportunity-right-sidebar-container {
    .details {
      border-left: 0 !important;
      padding-left: 0 !important;
    }
  }
}
</style>
