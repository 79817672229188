import { defineComponent } from '@vue/runtime-core';
import BaseModal from '@/core/components/ui/BaseModal.vue';
export default defineComponent({
    name: 'confirmation-modal',
    emits: ['close'],
    components: {
        BaseModal,
    },
    props: {
        show: Boolean,
    },
    data: () => {
        return {
            typeChoose: ''
        };
    },
    methods: {
        cancel() {
            this.$emit('close');
        },
        confirm() {
            this.$emit('close');
        }
    }
});
